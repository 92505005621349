import {
  ref, computed, onMounted, watch,
} from 'vue';
import { useRoute } from 'vue-router';

const bodyClass = () => {
  const route = useRoute();
  const body = ref(document.body);

  const pageStyling = computed(() => (route.path === '/' ? 'is-page-home' : `is-page-${route.path.slice(1)}`));

  onMounted(() => {
    body.value.classList.add(pageStyling.value);
  });

  watch(pageStyling, (newClass, oldClass) => {
    body.value.classList.remove(oldClass);
    body.value.classList.add(newClass);
  });
};

export default bodyClass;
