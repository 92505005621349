<script>
import FooterSubnav from '../components/FooterSubnav.vue';

export default {
  name: 'LayoutFooter',
  components: {
    FooterSubnav,
  },
};
</script>

<template>
  <footer class="footer">
    <div class="footer__contact">
      <div class="headline-large">
        <span>Если у вас</span> <span>есть вопросы</span>
      </div>

      <AppLink class="link--lightgray link--rarr body-medium" to="/404">Свяжитесь с нами</AppLink>
    </div>

    <div class="footer__subnav">
      <FooterSubnav />
    </div>

    <div class="footer__legals">
      <p class="body-small">
        <span>© 2022 — Проксима сити групп</span>
        <AppLink class="link--lightgray link--ticker" to="/404">ИГК</AppLink>
        <AppLink class="link--lightgray link--ticker" to="/404">Правовая информация</AppLink>
      </p>

      <AppIcon name="i-logotype" class="footer-logotype" />
    </div>

    <div class="footer__socials">
      <p class="body-small">Социальные сети</p>

      <div class="btn-group btn-group--merge">
        <AppButton
          theme="icon"
          size="sm"
          variation="text"
          shape="rounded"
          aria-label="Телеграм"
        >
          <AppIcon name="i-telegram"/>
        </AppButton>

        <AppButton
          theme="icon"
          size="sm"
          variation="text"
          shape="rounded"
          aria-label="Вконтакте"
        >
          <AppIcon name="i-vk"/>
        </AppButton>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
@import "../assets/scss/base/mixins";

.footer {
  --border-shadow-color: var(--c-border-dark);

  position: relative;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(auto-fill, auto);
  gap: 4rem 0;
  min-height: 50rem;
  padding: 6rem var(--gridline-padding) 6rem;
  background-color: var(--c-background-secondary);
  color: var(--c-text-tertiary);

  @include min-width(tablet-portrait) {
    grid-template-columns: 1.1fr 0.9fr;
    grid-template-rows: auto;
    gap: 1rem;
    padding: 9rem var(--gridline-padding) 6rem;
  }

  &__contact {
    padding-bottom: 2rem;

    @include min-width(tablet-portrait) {
      grid-column: 1;
      grid-row: 1;
      place-self: start start;
    }

    .headline-large {
      margin-bottom: 2rem;
      line-height: 1;
      font-size: 5.6em;

      @include min-width(tablet-portrait) {
        margin-bottom: 2.6rem;
        font-size: 3.2em;
      }

      span {
        display: block;
      }
    }
  }

  &__subnav {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 2rem;

    @include min-width(tablet-portrait) {
      grid-column: 2;
      grid-row: 1;
      place-self: start end;
      gap: 0 7rem;
    }
  }

  &__legals {
    position: relative;
    grid-row: 4;

    @include min-width(tablet-portrait) {
      grid-column: 1;
      grid-row: 2;
      place-self: end start;
    }

    @include min-width(tablet-portrait) {
      padding-left: 6.4rem;
    }

    @include min-width(tablet-landscape) {
      padding-left: 0;
    }

    p.body-small {
      @include max-width(tablet-portrait) {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: repeat(auto-fill, auto);
        gap: 1.5rem 0;
      }

      .link {
        @include min-width(tablet-portrait) {
          margin-left: 2rem;
        }
      }
    }

    .footer-logotype {
      position: relative;
      width: 34px;
      height: 39px;
      margin-top: 4.6rem;
      margin-left: calc(50% - 17px);
      color: var(--c-blue-royal);
      fill: var(--c-smoky-white);

      @include min-width(tablet-portrait) {
        position: absolute;
        inset: 0;
        margin-top: 0;
        margin-left: 0;
      }

      @include min-width(tablet-landscape) {
        inset: 0 auto 0 -6.4rem;
      }
    }
  }

  &__socials {
    grid-row: 3;
    display: flex;
    align-items: center;

    @include min-width(tablet-portrait) {
      grid-column: 2;
      grid-row: 2;
      place-self: end end;
    }

    .btn-group {
      margin-left: 3rem;
    }
  }

  @include border-side(top);

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 6px;
    position: absolute;
    inset: auto 0 0;
    z-index: 1;
    box-shadow: 0 -6px 0 0 inset var(--c-background-primary);
  }
}
</style>
