import { watch } from 'vue';

const metadata = (router) => watch(
  () => router.currentRoute.value,
  (to) => {
    document.title = to.meta.title;
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', to.meta.description);
  },
);

export default metadata;
