import { watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import preloader from '../utils/preloader';
import useLoading from '../store/loading';
import useTransition from '../store/transition';
import useDrawer from '../store/drawer';

const routing = () => {
  const router = useRouter();
  const drawer = useDrawer();
  const loading = useLoading();
  const transition = useTransition();

  const delay = (ms) => new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  });

  const beforeRoute = async () => { // Перед навигацией по маршруту
    if (!loading.first && !drawer.visible) { // Не первый переход по маршруту + дравер закрыт
      transition.playExpand(); // Начало анимации перехода
      await delay(1200);
    }

    loading.setComplete(false); // Сбрасываем значение готовности страницы
  };

  const afterRoute = async () => { // После навигации по маршруту
    if (loading.first) { // Выполняем один раз при загрузке приложения
      await loading.setFirst(false); // Сообщаем в стор, что первая загрузка была выполнена
      await preloader.animation(); // Запускаем анимацию исчезновения прелоадера
      await delay(750); // Ждем пока закончится анимация исчезновения прелоадера
      await transition.playCollapse(); // Выполняем выход из анимации перехода
    } else if (drawer.visible) { // Если был дравер открыт
      await drawer.setVisible(false); // Закрываем дравер
      await delay(200); // Из дравера переход между страницами быстрее чем по ссылкам, по этому еще немного ждём
    } else { // Или если это переход по внутренним ссылкам
      await transition.playCollapse(); // Выполняем выход из анимации перехода
    }

    await delay(600);
    await loading.setComplete(true); // Сообщаем в стор, что страница готова к hero анимациям
  };

  watchEffect(() => {
    router.beforeEach(async (to, from, next) => { // Выполняется перед каждой навигацией на новый маршрут
      await beforeRoute();

      next(); // Продолжает навигацию на новый маршрут
    });

    router.afterEach(async () => { // Выполняется после каждой навигации на новый маршрут
      await afterRoute();
    });
  });
};

export default routing;
