import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';

export default () => createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: async () => new Promise((resolve) => {
    resolve({ top: 0 });
  }),
});
