<script>
import {
  ref, onMounted, watch,
} from 'vue';
import { gsap } from 'gsap';
import useDrawer from '../store/drawer';

export default {
  name: 'AppDrawerNavAnnex',
  setup() {
    const drawer = useDrawer();
    const drawerNavAnnexRef = ref();

    const tlDrawerNavAnnexOpen = gsap.timeline({ paused: true });
    const tlDrawerNavAnnexClose = gsap.timeline({ paused: true });

    const animation = () => {
      const targets = gsap.utils.toArray(drawerNavAnnexRef.value.children);

      if (drawerNavAnnexRef.value) {
        tlDrawerNavAnnexOpen
          .set(drawerNavAnnexRef.value, {
            autoAlpha: 1,
            '--drawer-nav-annex-border': '100% 100% 0 0',
            '--drawer-nav-annex-scale': '0',
          })
          .set(targets, {
            y: 90,
            autoAlpha: 0,
          })
          .to(drawerNavAnnexRef.value, {
            duration: 0.6,
            autoAlpha: 1,
            '--drawer-nav-annex-border': '0% 0% 0 0',
            '--drawer-nav-annex-scale': '1',
          }, '<+=0.9')
          .to(targets, {
            duration: 0.4,
            y: 0,
            autoAlpha: 1,
            ease: 'sine.out',
            stagger: {
              amount: 0.1,
            },
          }, '<+=0.3');

        tlDrawerNavAnnexClose
          .fromTo(drawerNavAnnexRef.value, {
            autoAlpha: 1,
            ease: 'sine.out',
          }, {
            duration: 0.7,
            autoAlpha: 0,
          });
      }
    };

    onMounted(animation);

    watch(
      () => drawer.visible,
      (newValue) => {
        if (newValue) {
          tlDrawerNavAnnexOpen.restart();
        } else {
          tlDrawerNavAnnexClose.restart();
        }
      },
    );

    return {
      drawerNavAnnexRef,
    };
  },
};
</script>

<template lang="html">
  <div
    class="drawer-nav-annex"
    ref="drawerNavAnnexRef"
  >
    <div class="drawer-nav-annex__button">
      <AppButton
        route="contact"
        theme="big-white"
        size="lg"
        variation="outlined"
        shape="rounded"
      >
        <span class="label">Давайте знакомиться</span>
        <AppIcon name="i-rarr" />
      </AppButton>
    </div>
    <div class="drawer-nav-annex__links">
      <AppLink class="body-small link link--gray" to="/404">ИГК</AppLink>
      <AppLink class="body-small link link--gray" to="/404">Правовая информация</AppLink>
    </div>
    <div class="drawer-nav-annex__social">
      <AppButton
        theme="icon"
        size="sm"
        variation="text"
        shape="rounded"
        aria-label="Телеграм"
      >
        <AppIcon name="i-telegram"/>
      </AppButton>
      <AppButton
        theme="icon"
        size="sm"
        variation="text"
        shape="rounded"
        aria-label="Вконтакте"
      >
        <AppIcon name="i-vk"/>
      </AppButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
 @import "../assets/scss/base/mixins";

.drawer-nav-annex {
  overflow: clip;
  position: relative;
  padding: 5vh 4vw 5vh 12vw;
  display: grid;
  grid-auto-flow: column;
  grid-template: "button button" 70% "links social" 30% / 1fr 120px;
  gap: 10px 0;

  @include min-width(tablet-landscape) {
    padding: 5vh 6vw;
  }

  &__button {
    grid-area: button;
    align-self: center;
  }

  &__links {
    grid-area: links;
    align-self: center;

    .link {
      &:not(:last-child) {
        padding-right: 1rem;
      }
    }
  }

  &__social {
    grid-area: social;
    place-self: center end;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    transform-origin: bottom;
    background-color: #FFFFFF1A;
    border-radius: var(--drawer-nav-annex-border);
    transform: scaleY(var(--drawer-nav-annex-scale));
  }
}
</style>
