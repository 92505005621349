import { defineStore } from 'pinia';

const useDrawer = defineStore({
  id: 'useDrawer',
  state: () => ({
    visible: false,
  }),
  actions: {
    setVisible(value) {
      this.visible = value;
    },
  },
});

export default useDrawer;
