import Lenis from '@studio-freight/lenis';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const scrolling = {
  lenis: null,
  smooth: {
    create() {
      this.lenis = new Lenis();
      this.lenis.on('scroll', ScrollTrigger.update);

      gsap.ticker.lagSmoothing(0);

      const scrollSmooth = (time) => {
        if (!this.lenis) return;

        this.lenis.raf(time);
        requestAnimationFrame(scrollSmooth);
      };

      requestAnimationFrame(scrollSmooth);
    },
    destroy() {
      if (!this.lenis) return;

      this.lenis.destroy();
      this.lenis = null;
    },
    start() {
      if (!this.lenis) return;

      this.lenis.start();
    },
    stop() {
      if (!this.lenis) return;

      this.lenis.stop();
    },
  },
};

export default scrolling;
