<script>
import imageData from '../data/imageData';

export default {
  name: 'AppImage',
  props: {
    file: {
      type: String,
      required: true,
    },
  },
  setup() {
    const srcsetAvif = (file) => {
      if (imageData[file].source.avif) {
        return Object.entries(imageData[file].source.avif)
          .map(([key, value]) => `${value} ${key}w`)
          .join(', ');
      }
      return '';
    };

    const srcsetWebp = (file) => {
      if (imageData[file].source.webp) {
        return Object.entries(imageData[file].source.webp)
          .map(([key, value]) => `${value} ${key}w`)
          .join(', ');
      }
      return '';
    };

    const srcsetJpeg = (file) => {
      if (imageData[file].source.jpeg) {
        return Object.entries(imageData[file].source.jpeg)
          .map(([key, value]) => `${value} ${key}w`)
          .join(', ');
      }
      return '';
    };

    return {
      imageData,
      srcsetAvif,
      srcsetWebp,
      srcsetJpeg,
    };
  },
};
</script>

<template>
  <div class="image-container">
    <picture
      :style="
        imageData[file].background
          ? `background-image: url(${imageData[file].background});`
          : ''
      "
    >
      <source
        type="image/avif"
        :srcset="srcsetAvif(file)"
        :sizes="imageData[file].source.sizes"
        v-if="imageData[file].source.avif"
      />
      <source
        type="image/webp"
        :srcset="srcsetWebp(file)"
        :sizes="imageData[file].source.sizes"
        v-if="imageData[file].source.webp"
      />
      <source
        type="image/jpeg"
        :srcset="srcsetJpeg(file)"
        :sizes="imageData[file].source.sizes"
        v-if="imageData[file].source.jpeg"
      />
      <img
        v-if="imageData[file].img"
        :src="imageData[file].img?.src"
        :width="imageData[file].img?.width"
        :height="imageData[file].img?.height"
        :loading="imageData[file].img?.loading"
        :decoding="imageData[file].img?.decoding"
        :alt="imageData[file].img?.alt"
      />
    </picture>
  </div>
</template>

<style lang="scss" scoped>
.image-container {
  width: 100%;
  height: 100%;

  picture {
    display: flex;
    width: 100%;
    height: 100%;
    background-position: 50%;
    background-size: cover;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &[data-parallax] {
    position: relative;
    overflow: hidden;
  }
}
</style>
