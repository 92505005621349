<script>
import useMedia from '../store/media';
import AppLogotype from '../components/AppLogotype.vue';
import AppHamburger from '../components/AppHamburger.vue';

export default {
  name: 'LayoutHeader',
  components: {
    AppLogotype,
    AppHamburger,
  },
  setup() {
    const media = useMedia();

    return {
      media,
    };
  },
};
</script>

<template>
  <header class="header" ref="headerRef">
    <div
      class="gridline"
      :class="{
        'gridline--center': media.phone,
        'gridline--collapse': !media.phone,
      }"
    >
      <div class="header-logotype">
        <AppLogotype />
      </div>
      <div class="header-hamburger">
        <AppHamburger />
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
@import "../assets/scss/base/mixins";

.header {
  --border-shadow-color: var(--c-border-light);

  background-color: var(--background-color);

  body.is-page-home & {
    --border-shadow-color: var(--c-border-dark);

    background-color: var(--c-background-secondary);
  }

  body.is-page-contact & {
    background-color: var(--c-background-tertiary);
  }

  body.is-page-error & {
    @include max-width(tablet-portrait) {
      background-color: var(--c-background-quaternary);
    }
  }

  @include min-width(tablet-portrait) {
    @include border-side(bottom);
  }

  .gridline {
    --padding: var(--gridline-padding);
    --gap: 0px;

    @include max-width(tablet-portrait) {
      display: flex;
      justify-content: space-between;
    }
  }

  &-logotype {
    --column: 6.8;

    @include min-width(tablet-portrait) {
      display: grid;
      place-content: center start;

      > * {
        position: relative;
        padding-right: 6rem;

        &::after {
          content: '';
          position: absolute;
          inset: 0;

          @include border-side(right);

          pointer-events: none;
        }
      }
    }
  }

  &-hamburger {
    --column: 5.2;

    @include min-width(tablet-portrait) {
      padding-left: 2.2rem;

      @include border-side(left);
    }
  }
}
</style>
