import { defineStore } from 'pinia';

const useLoading = defineStore({
  id: 'useLoading',
  state: () => ({
    first: true,
    complete: false,
  }),
  actions: {
    setFirst(value) {
      this.first = value;
    },
    setComplete(value) {
      this.complete = value;
    },
  },
});

export default useLoading;
