<script>
import { ref } from 'vue';

export default {
  name: 'FooterSubnav',
  setup() {
    const navFooterSubnavRef = ref([
      { text: 'Компания', to: '/' },
      { text: 'История', to: '/history' },
      { text: 'Проекты', to: '/decision' },
      { text: 'Контакты', to: '/contact' },
    ]);

    const navFooterAnnexRef = ref([
      { text: 'Проектирование', to: '/404' },
      { text: 'Топография', to: '/404' },
      { text: 'Мониторинг', to: '/404' },
      { text: 'Геополимеры', to: '/404' },
      { text: 'Механизация', to: '/404' },
    ]);

    return {
      navFooterSubnavRef,
      navFooterAnnexRef,
    };
  },
};
</script>

<template lang="html">
  <nav class="footer-subnav">
    <span class="title-small">Разделы</span>

    <AppLink
      v-for="item in navFooterSubnavRef"
      :key="item"
      class="link--lightgray link--ticker body-medium"
      :throttle="1000"
      :to="item.to"
    >
      {{ item.text }}
    </AppLink>
  </nav>
  <nav class="footer-subnav">
    <span class="title-small">Технологии</span>

    <AppLink
      v-for="item in navFooterAnnexRef"
      :key="item"
      class="link--lightgray link--ticker body-medium"
      :throttle="1000"
      :to="item.to"
    >
      {{ item.text }}
    </AppLink>
  </nav>
</template>

<style lang="scss" scoped>
@import "../assets/scss/base/mixins";

.footer-subnav {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  .title-small {
    margin-top: 0;
    margin-bottom: 2rem;
    color: var(--c-text-secondary);
  }
}
</style>
