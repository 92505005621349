<script>
import { onMounted, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import routing from './router/routing';
import metadata from './utils/metadata';
import bodyClass from './utils/bodyClass';
// import reloadOnResize from './utils/reloadOnResize';
import scrolling from './utils/lenis';
import useMedia from './store/media';
import LayoutMain from './layout/LayoutMain.vue';

export default {
  name: 'App',
  components: {
    LayoutMain,
  },
  setup() {
    const router = useRouter();
    const media = useMedia();

    routing(); // Операции сопровождающие переходы по маршрутам
    bodyClass(); // Стилизующий класс для каждой стрницы

    onMounted(() => {
      metadata(router); // Мета тайтлы и дескрипшоны
      media.initTrigger(); // Отслеживание изменений устройств ввода и размера окна браузера
      // reloadOnResize();

      // if ('serviceWorker' in navigator) { TODO
      //   navigator.serviceWorker.register(
      //     new URL('./service-worker.js', import.meta.url),
      //     { type: 'module' },
      //   );
      // }
    });

    watchEffect(() => { // Ленис
      if (media.touch) {
        scrolling.smooth.create();
      } else {
        scrolling.smooth.destroy();
      }
    });
  },
};
</script>

<template>
  <LayoutMain>
    <routerView></routerView>
  </LayoutMain>
</template>
