<script>
import {
  ref, onMounted, watchEffect, watch,
} from 'vue';
import { gsap } from 'gsap';
import { lock, unlock } from 'tua-body-scroll-lock';
import scrolling from '../utils/lenis';
import useDrawer from '../store/drawer';
import AppDrawerNavMenu from './AppDrawerNavMenu.vue';
import AppDrawerNavAnnex from './AppDrawerNavAnnex.vue';

export default {
  name: 'AppDrawerNav',
  components: {
    AppDrawerNavMenu,
    AppDrawerNavAnnex,
  },
  setup() {
    const drawer = useDrawer();
    const drawerNavRef = ref();

    const tlDrawerNav = gsap.timeline({
      paused: true,
    });

    const animation = () => {
      if (drawerNavRef.value) {
        tlDrawerNav
          .to(drawerNavRef.value, {
            duration: 1.5,
            yPercent: 101,
            ease: 'power4.inOut',
            force3D: true,
          }, '<');
      }
    };

    onMounted(animation);

    watchEffect(() => {
      if (drawer.visible) {
        tlDrawerNav.restart();
      } else {
        tlDrawerNav.reverse();
      }
    });

    watch(
      () => drawer.visible,
      (newValue) => {
        if (newValue) {
          scrolling.smooth.stop();
          lock(drawerNavRef.value, { overflowType: 'clip' });
        } else {
          scrolling.smooth.start();
          unlock(drawerNavRef.value);
        }
      },
    );

    return {
      drawerNavRef,
    };
  },
};
</script>

<template lang="html">
  <div
    ref="drawerNavRef"
    class="drawer-nav custom-scrollbar"
    data-lenis-prevent
  >
    <AppDrawerNavMenu />
    <AppDrawerNavAnnex />
  </div>
</template>

<style lang="scss" scoped>
.drawer-nav {
  pointer-events: auto;
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: var(--c-background-primary);
  will-change: transform;
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr minmax(200px, 30vh);
  row-gap: 40px;
}
</style>
