<script>
import useMedia from '../store/media';

export default {
  name: 'AppLogotype',
  setup() {
    const media = useMedia();

    return {
      media,
    };
  },
};
</script>

<template lang="html">
  <AppLink
    class="link--logotype"
    to="/"
    :throttle="1000"
  >
    <div class="logotype">
      <div class="logotype__icon">
        <AppIcon name="i-logotype" />
      </div>
      <div
        v-if="media.tablet"
        class="logotype__text"
      >
        <span class="name">Проксима</span>
        <span class="slogan">сити групп</span>
      </div>
    </div>
  </AppLink>
</template>

<style lang="scss" scoped>
@import "../assets/scss/base/mixins";

.logotype {
  --color-one: var(--c-blue-royal);
  --color-two: var(--c-black-blue);

  z-index: var(--z-index-header-logotype);

  body.is-drawer .header-logotype & {
    --color-one: var(--c-white);
    --color-two: var(--c-white);

    fill: white;
    color: white;
  }

  body.is-page-home .header-logotype & {
    --color-two: var(--c-smoky-white);
  }

  position: relative;
  display: flex;
  align-items: center;
  height: var(--header-height);
  font-family: var(--font-family-logotype);
  text-decoration: none;

  &__icon {
    display: inline-flex;
    padding: 1.5rem;

    @include min-width(tablet-landscape) {
      padding: 0 1.5rem 0 .1rem;
    }

    .svg-icon {
      width: 34px;
      height: 39px;
      color: var(--color-one);
      fill: var(--color-two);
      transition: all 225ms 275ms ease;
    }
  }

  &__text {
    position: relative;
    line-height: 1;

    span {
      padding-left: 6px;
      white-space: nowrap;
      text-transform: uppercase;
      display: flex;
      line-height: 1.2;
      transition: all 225ms 275ms ease;

      &.name {
        font-size: 18px;
        font-weight: 700;
        line-height: 1;
        color: var(--color-one);
      }

      &.slogan {
        padding-top: 4px;
        font-size: 12px;
        font-weight: 300;
        line-height: 1;
        color: var(--color-two);
        transform: translateX(1px);
      }
    }
  }
}
</style>
