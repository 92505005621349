import { defineStore } from 'pinia';
import MediaTrigger from 'media-trigger';
import mediaQuery from '../utils/mediaQuery';

const useMedia = defineStore({
  id: 'useMedia',
  state: () => ({
    phone: true,
    tablet: true,
    touch: true,
  }),
  actions: {
    setPhone(value) {
      this.phone = value;
    },
    setTablet(value) {
      this.tablet = value;
    },
    setTouch(value) {
      this.touch = value;
    },
    initTrigger() {
      this.createTrigger(
        'phone',
        () => { this.setPhone(true); },
        () => { this.setPhone(false); },
      );
      this.createTrigger(
        'tablet',
        () => { this.setTablet(true); },
        () => { this.setTablet(false); },
      );
      this.createTrigger(
        'touch',
        () => { this.setTouch(true); },
        () => { this.setTouch(false); },
      );
    },
    createTrigger(media, entry, exit) {
      return new MediaTrigger({
        media: mediaQuery(media),
        entry,
        exit,
      });
    },
  },
});

export default useMedia;
