import gsap from 'gsap';

const preloader = {
  preloaderClass: '.app-preloader',
  animation() {
    gsap.timeline().to(this.preloaderClass, {
      ease: 'power3.in',
      duration: 1,
      autoAlpha: 0,
      yPercent: -15,
      onComplete: () => {
        document.querySelector(this.preloaderClass).remove();
        document.body.style.backgroundColor = null;
      },
    });
  },
};

export default preloader;
