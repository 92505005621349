const routes = [
  {
    path: '/',
    component: () => import('../views/ViewHome.vue'),
    meta: {
      title: 'Проксима • партнер ваших строительных проектов',
      description: 'О компании Проксима',
    },
  },
  {
    path: '/history',
    component: () => import('../views/ViewHistory.vue'),
    meta: {
      title: 'История • Проксима',
      description: 'История компании Проксима',
    },
  },
  {
    path: '/decision',
    component: () => import('../views/ViewDecision.vue'),
    meta: {
      title: 'Решения • Проксима',
      description: 'Решения компании Проксима',
    },
  },
  {
    path: '/contact',
    component: () => import('../views/ViewContact.vue'),
    meta: {
      title: 'Контакты • Проксима',
      description: 'Контактная информация компании Проксима',
    },
  },
  {
    path: '/error',
    component: () => import('../views/ViewError.vue'),
    meta: {
      title: '404 • Проксима',
      description: '404 Страница не найдена',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/error',
  },
];

export default routes;
