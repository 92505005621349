<script>
import {
  ref, onMounted, watchEffect, nextTick,
} from 'vue';
import { gsap } from 'gsap';
import useDrawer from '../store/drawer';

export default {
  name: 'AppDrawerImage',
  setup() {
    const drawer = useDrawer();
    const drawerImageRef = ref();

    const tlDrawerImage = gsap.timeline({
      paused: true,
      defaults: {
        ease: 'power4.inOut',
      },
    });

    const animation = async () => {
      await nextTick();

      if (drawerImageRef.value) {
        tlDrawerImage
          .set(drawerImageRef.value.children, {
            yPercent: 40,
          }, '<')
          .to(drawerImageRef.value, {
            duration: 1.5,
            yPercent: 101,
          }, '<')
          .to(drawerImageRef.value.children, {
            duration: 1.5,
            scale: 1.3,
            yPercent: 0,
          }, '<');
      }
    };

    onMounted(animation);

    watchEffect(() => {
      if (drawer.visible) {
        tlDrawerImage.timeScale(0.8).restart();
      } else {
        tlDrawerImage.timeScale(1.2).reverse();
      }
    });

    return {
      drawerImageRef,
    };
  },
};
</script>

<template lang="html">
  <div ref="drawerImageRef" class="drawer-image">
    <AppImage file="drawer_banner" />
  </div>
</template>

<style lang="scss" scoped>
.drawer-image {
  overflow: hidden;
  pointer-events: auto;
  will-change: transform;

  .image-container {
    transform-origin: 80% bottom;

    picture {
      background-position: center right;
    }
  }
}
</style>
