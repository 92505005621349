const mediaTypes = new Map([
  ['phone', '(min-width: 992px)'],
  ['tablet', '(min-width: 1200px)'],
  ['touch', '(min-width: 1200px) and (any-pointer: fine)'],
]);

function mediaQuery(mediaType) {
  return mediaTypes.get(mediaType) || '';
}

export default mediaQuery;
