const imageData = {
  drawer_banner: {
    img: {
      alt: 'test test',
      width: 2320,
      height: 2000,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/drawer_banner.png?as=jpeg&width=1160',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        800: new URL(
          '../assets/images/drawer_banner.png?as=avif&width=800',
          import.meta.url,
        ),
        1160: new URL(
          '../assets/images/drawer_banner.png?as=avif&width=1160',
          import.meta.url,
        ),
        1740: new URL(
          '../assets/images/drawer_banner.png?as=avif&width=1740',
          import.meta.url,
        ),
        2320: new URL(
          '../assets/images/drawer_banner.png?as=avif&width=2320',
          import.meta.url,
        ),
      },
      webp: {
        800: new URL(
          '../assets/images/drawer_banner.png?as=webp&width=800',
          import.meta.url,
        ),
        1160: new URL(
          '../assets/images/drawer_banner.png?as=webp&width=1160',
          import.meta.url,
        ),
        1740: new URL(
          '../assets/images/drawer_banner.png?as=webp&width=1740',
          import.meta.url,
        ),
        2320: new URL(
          '../assets/images/drawer_banner.png?as=webp&width=2320',
          import.meta.url,
        ),
      },
      jpeg: {
        800: new URL(
          '../assets/images/drawer_banner.png?as=jpeg&width=800',
          import.meta.url,
        ),
        1160: new URL(
          '../assets/images/drawer_banner.png?as=jpeg&width=1160',
          import.meta.url,
        ),
        1740: new URL(
          '../assets/images/drawer_banner.png?as=jpeg&width=1740',
          import.meta.url,
        ),
        2320: new URL(
          '../assets/images/drawer_banner.png?as=jpeg&width=2320',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 1200px) 70vw, 1200px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  parallax_stretch_01: {
    img: {
      alt: 'test test',
      width: 2400,
      height: 1560,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/parallax_stretch_01.png?as=jpeg&width=1920',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        640: new URL(
          '../assets/images/parallax_stretch_01.png?as=avif&width=640',
          import.meta.url,
        ),
        960: new URL(
          '../assets/images/parallax_stretch_01.png?as=avif&width=960',
          import.meta.url,
        ),
        1280: new URL(
          '../assets/images/parallax_stretch_01.png?as=avif&width=1280',
          import.meta.url,
        ),
        1680: new URL(
          '../assets/images/parallax_stretch_01.png?as=avif&width=1680',
          import.meta.url,
        ),
        1920: new URL(
          '../assets/images/parallax_stretch_01.png?as=avif&width=1920',
          import.meta.url,
        ),
        2400: new URL(
          '../assets/images/parallax_stretch_01.png?as=avif&width=2400',
          import.meta.url,
        ),
      },
      webp: {
        640: new URL(
          '../assets/images/parallax_stretch_01.png?as=webp&width=640',
          import.meta.url,
        ),
        960: new URL(
          '../assets/images/parallax_stretch_01.png?as=webp&width=960',
          import.meta.url,
        ),
        1280: new URL(
          '../assets/images/parallax_stretch_01.png?as=webp&width=1280',
          import.meta.url,
        ),
        1680: new URL(
          '../assets/images/parallax_stretch_01.png?as=webp&width=1680',
          import.meta.url,
        ),
        1920: new URL(
          '../assets/images/parallax_stretch_01.png?as=webp&width=1920',
          import.meta.url,
        ),
        2400: new URL(
          '../assets/images/parallax_stretch_01.png?as=webp&width=2400',
          import.meta.url,
        ),
      },
      jpeg: {
        640: new URL(
          '../assets/images/parallax_stretch_01.png?as=jpeg&width=640',
          import.meta.url,
        ),
        960: new URL(
          '../assets/images/parallax_stretch_01.png?as=jpeg&width=960',
          import.meta.url,
        ),
        1280: new URL(
          '../assets/images/parallax_stretch_01.png?as=jpeg&width=1280',
          import.meta.url,
        ),
        1680: new URL(
          '../assets/images/parallax_stretch_01.png?as=jpeg&width=1680',
          import.meta.url,
        ),
        1920: new URL(
          '../assets/images/parallax_stretch_01.png?as=jpeg&width=1920',
          import.meta.url,
        ),
        2400: new URL(
          '../assets/images/parallax_stretch_01.png?as=jpeg&width=2400',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 1920px) 100vw, 1920px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  parallax_stretch_02: {
    img: {
      alt: 'test test',
      width: 2400,
      height: 1560,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/parallax_stretch_02.png?as=jpeg&width=1920',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        640: new URL(
          '../assets/images/parallax_stretch_02.png?as=avif&width=640',
          import.meta.url,
        ),
        960: new URL(
          '../assets/images/parallax_stretch_02.png?as=avif&width=960',
          import.meta.url,
        ),
        1280: new URL(
          '../assets/images/parallax_stretch_02.png?as=avif&width=1280',
          import.meta.url,
        ),
        1680: new URL(
          '../assets/images/parallax_stretch_02.png?as=avif&width=1680',
          import.meta.url,
        ),
        1920: new URL(
          '../assets/images/parallax_stretch_02.png?as=avif&width=1920',
          import.meta.url,
        ),
        2400: new URL(
          '../assets/images/parallax_stretch_02.png?as=avif&width=2400',
          import.meta.url,
        ),
      },
      webp: {
        640: new URL(
          '../assets/images/parallax_stretch_02.png?as=webp&width=640',
          import.meta.url,
        ),
        960: new URL(
          '../assets/images/parallax_stretch_02.png?as=webp&width=960',
          import.meta.url,
        ),
        1280: new URL(
          '../assets/images/parallax_stretch_02.png?as=webp&width=1280',
          import.meta.url,
        ),
        1680: new URL(
          '../assets/images/parallax_stretch_02.png?as=webp&width=1680',
          import.meta.url,
        ),
        1920: new URL(
          '../assets/images/parallax_stretch_02.png?as=webp&width=1920',
          import.meta.url,
        ),
        2400: new URL(
          '../assets/images/parallax_stretch_02.png?as=webp&width=2400',
          import.meta.url,
        ),
      },
      jpeg: {
        640: new URL(
          '../assets/images/parallax_stretch_02.png?as=jpeg&width=640',
          import.meta.url,
        ),
        960: new URL(
          '../assets/images/parallax_stretch_02.png?as=jpeg&width=960',
          import.meta.url,
        ),
        1280: new URL(
          '../assets/images/parallax_stretch_02.png?as=jpeg&width=1280',
          import.meta.url,
        ),
        1680: new URL(
          '../assets/images/parallax_stretch_02.png?as=jpeg&width=1680',
          import.meta.url,
        ),
        1920: new URL(
          '../assets/images/parallax_stretch_02.png?as=jpeg&width=1920',
          import.meta.url,
        ),
        2400: new URL(
          '../assets/images/parallax_stretch_02.png?as=jpeg&width=2400',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 1920px) 100vw, 1920px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  parallax_stretch_04: {
    img: {
      alt: 'test test',
      width: 2400,
      height: 1560,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/parallax_stretch_04.png?as=jpeg&width=1920',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        640: new URL(
          '../assets/images/parallax_stretch_04.png?as=avif&width=640',
          import.meta.url,
        ),
        960: new URL(
          '../assets/images/parallax_stretch_04.png?as=avif&width=960',
          import.meta.url,
        ),
        1280: new URL(
          '../assets/images/parallax_stretch_04.png?as=avif&width=1280',
          import.meta.url,
        ),
        1680: new URL(
          '../assets/images/parallax_stretch_04.png?as=avif&width=1680',
          import.meta.url,
        ),
        1920: new URL(
          '../assets/images/parallax_stretch_04.png?as=avif&width=1920',
          import.meta.url,
        ),
        2400: new URL(
          '../assets/images/parallax_stretch_04.png?as=avif&width=2400',
          import.meta.url,
        ),
      },
      webp: {
        640: new URL(
          '../assets/images/parallax_stretch_04.png?as=webp&width=640',
          import.meta.url,
        ),
        960: new URL(
          '../assets/images/parallax_stretch_04.png?as=webp&width=960',
          import.meta.url,
        ),
        1280: new URL(
          '../assets/images/parallax_stretch_04.png?as=webp&width=1280',
          import.meta.url,
        ),
        1680: new URL(
          '../assets/images/parallax_stretch_04.png?as=webp&width=1680',
          import.meta.url,
        ),
        1920: new URL(
          '../assets/images/parallax_stretch_04.png?as=webp&width=1920',
          import.meta.url,
        ),
        2400: new URL(
          '../assets/images/parallax_stretch_04.png?as=webp&width=2400',
          import.meta.url,
        ),
      },
      jpeg: {
        640: new URL(
          '../assets/images/parallax_stretch_04.png?as=jpeg&width=640',
          import.meta.url,
        ),
        960: new URL(
          '../assets/images/parallax_stretch_04.png?as=jpeg&width=960',
          import.meta.url,
        ),
        1280: new URL(
          '../assets/images/parallax_stretch_04.png?as=jpeg&width=1280',
          import.meta.url,
        ),
        1680: new URL(
          '../assets/images/parallax_stretch_04.png?as=jpeg&width=1680',
          import.meta.url,
        ),
        1920: new URL(
          '../assets/images/parallax_stretch_04.png?as=jpeg&width=1920',
          import.meta.url,
        ),
        2400: new URL(
          '../assets/images/parallax_stretch_04.png?as=jpeg&width=2400',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 1920px) 100vw, 1920px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  parallax_stretch_05: {
    img: {
      alt: 'test test',
      width: 2400,
      height: 1560,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/parallax_stretch_05.png?as=jpeg&width=1920',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        640: new URL(
          '../assets/images/parallax_stretch_05.png?as=avif&width=640',
          import.meta.url,
        ),
        960: new URL(
          '../assets/images/parallax_stretch_05.png?as=avif&width=960',
          import.meta.url,
        ),
        1280: new URL(
          '../assets/images/parallax_stretch_05.png?as=avif&width=1280',
          import.meta.url,
        ),
        1680: new URL(
          '../assets/images/parallax_stretch_05.png?as=avif&width=1680',
          import.meta.url,
        ),
        1920: new URL(
          '../assets/images/parallax_stretch_05.png?as=avif&width=1920',
          import.meta.url,
        ),
        2400: new URL(
          '../assets/images/parallax_stretch_05.png?as=avif&width=2400',
          import.meta.url,
        ),
      },
      webp: {
        640: new URL(
          '../assets/images/parallax_stretch_05.png?as=webp&width=640',
          import.meta.url,
        ),
        960: new URL(
          '../assets/images/parallax_stretch_05.png?as=webp&width=960',
          import.meta.url,
        ),
        1280: new URL(
          '../assets/images/parallax_stretch_05.png?as=webp&width=1280',
          import.meta.url,
        ),
        1680: new URL(
          '../assets/images/parallax_stretch_05.png?as=webp&width=1680',
          import.meta.url,
        ),
        1920: new URL(
          '../assets/images/parallax_stretch_05.png?as=webp&width=1920',
          import.meta.url,
        ),
        2400: new URL(
          '../assets/images/parallax_stretch_05.png?as=webp&width=2400',
          import.meta.url,
        ),
      },
      jpeg: {
        640: new URL(
          '../assets/images/parallax_stretch_05.png?as=jpeg&width=640',
          import.meta.url,
        ),
        960: new URL(
          '../assets/images/parallax_stretch_05.png?as=jpeg&width=960',
          import.meta.url,
        ),
        1280: new URL(
          '../assets/images/parallax_stretch_05.png?as=jpeg&width=1280',
          import.meta.url,
        ),
        1680: new URL(
          '../assets/images/parallax_stretch_05.png?as=jpeg&width=1680',
          import.meta.url,
        ),
        1920: new URL(
          '../assets/images/parallax_stretch_05.png?as=jpeg&width=1920',
          import.meta.url,
        ),
        2400: new URL(
          '../assets/images/parallax_stretch_05.png?as=jpeg&width=2400',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 1920px) 100vw, 1920px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  poster_04: {
    img: {
      alt: 'test test',
      width: 1800,
      height: 2000,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/poster_04.png?as=jpeg&width=1500',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        420: new URL(
          '../assets/images/poster_04.png?as=avif&width=420',
          import.meta.url,
        ),
        680: new URL(
          '../assets/images/poster_04.png?as=avif&width=680',
          import.meta.url,
        ),
        900: new URL(
          '../assets/images/poster_04.png?as=avif&width=900',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/poster_04.png?as=avif&width=1200',
          import.meta.url,
        ),
        1500: new URL(
          '../assets/images/poster_04.png?as=avif&width=1500',
          import.meta.url,
        ),
        1800: new URL(
          '../assets/images/poster_04.png?as=avif&width=1800',
          import.meta.url,
        ),
      },
      webp: {
        420: new URL(
          '../assets/images/poster_04.png?as=webp&width=420',
          import.meta.url,
        ),
        680: new URL(
          '../assets/images/poster_04.png?as=webp&width=680',
          import.meta.url,
        ),
        900: new URL(
          '../assets/images/poster_04.png?as=webp&width=900',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/poster_04.png?as=webp&width=1200',
          import.meta.url,
        ),
        1500: new URL(
          '../assets/images/poster_04.png?as=webp&width=1500',
          import.meta.url,
        ),
        1800: new URL(
          '../assets/images/poster_04.png?as=webp&width=1800',
          import.meta.url,
        ),
      },
      jpeg: {
        420: new URL(
          '../assets/images/poster_04.png?as=jpeg&width=420',
          import.meta.url,
        ),
        680: new URL(
          '../assets/images/poster_04.png?as=jpeg&width=680',
          import.meta.url,
        ),
        900: new URL(
          '../assets/images/poster_04.png?as=jpeg&width=900',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/poster_04.png?as=jpeg&width=1200',
          import.meta.url,
        ),
        1500: new URL(
          '../assets/images/poster_04.png?as=jpeg&width=1500',
          import.meta.url,
        ),
        1800: new URL(
          '../assets/images/poster_04.png?as=jpeg&width=1800',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 900px) 100vw, 900px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  poster_05: {
    img: {
      alt: 'test test',
      width: 1800,
      height: 2000,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/poster_05.png?as=jpeg&width=1500',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        420: new URL(
          '../assets/images/poster_05.png?as=avif&width=420',
          import.meta.url,
        ),
        680: new URL(
          '../assets/images/poster_05.png?as=avif&width=680',
          import.meta.url,
        ),
        900: new URL(
          '../assets/images/poster_05.png?as=avif&width=900',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/poster_05.png?as=avif&width=1200',
          import.meta.url,
        ),
        1500: new URL(
          '../assets/images/poster_05.png?as=avif&width=1500',
          import.meta.url,
        ),
        1800: new URL(
          '../assets/images/poster_05.png?as=avif&width=1800',
          import.meta.url,
        ),
      },
      webp: {
        420: new URL(
          '../assets/images/poster_05.png?as=webp&width=420',
          import.meta.url,
        ),
        680: new URL(
          '../assets/images/poster_05.png?as=webp&width=680',
          import.meta.url,
        ),
        900: new URL(
          '../assets/images/poster_05.png?as=webp&width=900',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/poster_05.png?as=webp&width=1200',
          import.meta.url,
        ),
        1500: new URL(
          '../assets/images/poster_05.png?as=webp&width=1500',
          import.meta.url,
        ),
        1800: new URL(
          '../assets/images/poster_05.png?as=webp&width=1800',
          import.meta.url,
        ),
      },
      jpeg: {
        420: new URL(
          '../assets/images/poster_05.png?as=jpeg&width=420',
          import.meta.url,
        ),
        680: new URL(
          '../assets/images/poster_05.png?as=jpeg&width=680',
          import.meta.url,
        ),
        900: new URL(
          '../assets/images/poster_05.png?as=jpeg&width=900',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/poster_05.png?as=jpeg&width=1200',
          import.meta.url,
        ),
        1500: new URL(
          '../assets/images/poster_05.png?as=jpeg&width=1500',
          import.meta.url,
        ),
        1800: new URL(
          '../assets/images/poster_05.png?as=jpeg&width=1800',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 900px) 100vw, 900px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  poster_06: {
    img: {
      alt: 'test test',
      width: 1800,
      height: 2000,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/poster_06.png?as=jpeg&width=1500',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        420: new URL(
          '../assets/images/poster_06.png?as=avif&width=420',
          import.meta.url,
        ),
        680: new URL(
          '../assets/images/poster_06.png?as=avif&width=680',
          import.meta.url,
        ),
        900: new URL(
          '../assets/images/poster_06.png?as=avif&width=900',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/poster_06.png?as=avif&width=1200',
          import.meta.url,
        ),
        1500: new URL(
          '../assets/images/poster_06.png?as=avif&width=1500',
          import.meta.url,
        ),
        1800: new URL(
          '../assets/images/poster_06.png?as=avif&width=1800',
          import.meta.url,
        ),
      },
      webp: {
        420: new URL(
          '../assets/images/poster_06.png?as=webp&width=420',
          import.meta.url,
        ),
        680: new URL(
          '../assets/images/poster_06.png?as=webp&width=680',
          import.meta.url,
        ),
        900: new URL(
          '../assets/images/poster_06.png?as=webp&width=900',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/poster_06.png?as=webp&width=1200',
          import.meta.url,
        ),
        1500: new URL(
          '../assets/images/poster_06.png?as=webp&width=1500',
          import.meta.url,
        ),
        1800: new URL(
          '../assets/images/poster_06.png?as=webp&width=1800',
          import.meta.url,
        ),
      },
      jpeg: {
        420: new URL(
          '../assets/images/poster_06.png?as=jpeg&width=420',
          import.meta.url,
        ),
        680: new URL(
          '../assets/images/poster_06.png?as=jpeg&width=680',
          import.meta.url,
        ),
        900: new URL(
          '../assets/images/poster_06.png?as=jpeg&width=900',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/poster_06.png?as=jpeg&width=1200',
          import.meta.url,
        ),
        1500: new URL(
          '../assets/images/poster_06.png?as=jpeg&width=1500',
          import.meta.url,
        ),
        1800: new URL(
          '../assets/images/poster_06.png?as=jpeg&width=1800',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 700px) 100vw, 700px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  poster_09: {
    img: {
      alt: 'test test',
      width: 1800,
      height: 2000,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/poster_09.png?as=jpeg&width=1500',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        420: new URL(
          '../assets/images/poster_09.png?as=avif&width=420',
          import.meta.url,
        ),
        680: new URL(
          '../assets/images/poster_09.png?as=avif&width=680',
          import.meta.url,
        ),
        900: new URL(
          '../assets/images/poster_09.png?as=avif&width=900',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/poster_09.png?as=avif&width=1200',
          import.meta.url,
        ),
        1500: new URL(
          '../assets/images/poster_09.png?as=avif&width=1500',
          import.meta.url,
        ),
        1800: new URL(
          '../assets/images/poster_09.png?as=avif&width=1800',
          import.meta.url,
        ),
      },
      webp: {
        420: new URL(
          '../assets/images/poster_09.png?as=webp&width=420',
          import.meta.url,
        ),
        680: new URL(
          '../assets/images/poster_09.png?as=webp&width=680',
          import.meta.url,
        ),
        900: new URL(
          '../assets/images/poster_09.png?as=webp&width=900',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/poster_09.png?as=webp&width=1200',
          import.meta.url,
        ),
        1500: new URL(
          '../assets/images/poster_09.png?as=webp&width=1500',
          import.meta.url,
        ),
        1800: new URL(
          '../assets/images/poster_09.png?as=webp&width=1800',
          import.meta.url,
        ),
      },
      jpeg: {
        420: new URL(
          '../assets/images/poster_09.png?as=jpeg&width=420',
          import.meta.url,
        ),
        680: new URL(
          '../assets/images/poster_09.png?as=jpeg&width=680',
          import.meta.url,
        ),
        900: new URL(
          '../assets/images/poster_09.png?as=jpeg&width=900',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/poster_09.png?as=jpeg&width=1200',
          import.meta.url,
        ),
        1500: new URL(
          '../assets/images/poster_09.png?as=jpeg&width=1500',
          import.meta.url,
        ),
        1800: new URL(
          '../assets/images/poster_09.png?as=jpeg&width=1800',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 900px) 100vw, 900px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  poster_10: {
    img: {
      alt: 'test test',
      width: 1800,
      height: 2000,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/poster_10.png?as=jpeg&width=1500',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        420: new URL(
          '../assets/images/poster_10.png?as=avif&width=420',
          import.meta.url,
        ),
        680: new URL(
          '../assets/images/poster_10.png?as=avif&width=680',
          import.meta.url,
        ),
        900: new URL(
          '../assets/images/poster_10.png?as=avif&width=900',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/poster_10.png?as=avif&width=1200',
          import.meta.url,
        ),
        1500: new URL(
          '../assets/images/poster_10.png?as=avif&width=1500',
          import.meta.url,
        ),
        1800: new URL(
          '../assets/images/poster_10.png?as=avif&width=1800',
          import.meta.url,
        ),
      },
      webp: {
        420: new URL(
          '../assets/images/poster_10.png?as=webp&width=420',
          import.meta.url,
        ),
        680: new URL(
          '../assets/images/poster_10.png?as=webp&width=680',
          import.meta.url,
        ),
        900: new URL(
          '../assets/images/poster_10.png?as=webp&width=900',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/poster_10.png?as=webp&width=1200',
          import.meta.url,
        ),
        1500: new URL(
          '../assets/images/poster_10.png?as=webp&width=1500',
          import.meta.url,
        ),
        1800: new URL(
          '../assets/images/poster_10.png?as=webp&width=1800',
          import.meta.url,
        ),
      },
      jpeg: {
        420: new URL(
          '../assets/images/poster_10.png?as=jpeg&width=420',
          import.meta.url,
        ),
        680: new URL(
          '../assets/images/poster_10.png?as=jpeg&width=680',
          import.meta.url,
        ),
        900: new URL(
          '../assets/images/poster_10.png?as=jpeg&width=900',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/poster_10.png?as=jpeg&width=1200',
          import.meta.url,
        ),
        1500: new URL(
          '../assets/images/poster_10.png?as=jpeg&width=1500',
          import.meta.url,
        ),
        1800: new URL(
          '../assets/images/poster_10.png?as=jpeg&width=1800',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 900px) 100vw, 900px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
};

export default imageData;
