<script>
import { ref } from 'vue';
import useDrawer from '../store/drawer';
import useMedia from '../store/media';

export default {
  name: 'AppHamburger',
  setup() {
    const drawer = useDrawer();
    const media = useMedia();
    const isThrottled = ref(true);

    const toggleDrawer = () => {
      if (isThrottled.value) {
        isThrottled.value = false;

        setTimeout(() => {
          isThrottled.value = true;
        }, 1000);

        const newValue = !drawer.visible;

        drawer.setVisible(newValue);
      }
    };

    return {
      toggleDrawer,
      media,
    };
  },
};
</script>

<template lang="html">
  <div
    @click="toggleDrawer"
    class="hamburger"
    aria-label="Меню"
    role="button"
  >
    <svg class="hamburger-svg" xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="none">
      <path class="ham-d-one" d="M-1 37.5h82"/>
      <path class="ham-d-two" d="M-1 43.5h82"/>
      <path class="ham-m-one" d="M23.966 37h32C66.5 37 70 44 68 50c-2 6-10.5 7.5-16.5 1.5S28.666 28.7 28.666 28.7"/>
      <path class="ham-m-two" d="M23.966 43h32C66.5 43 70 36 68 30c-2-6-10.5-7.5-16.5-1.5S28.666 51.3 28.666 51.3"/>
      <path class="ham-close" d="m28.5 51.5 53-53M51.5 51.5l-53-53"/>
    </svg>
    <div
      v-if="media.phone"
      class="hamburger-label"
    >
      <span>м</span>
      <span>е</span>
      <span>н</span>
      <span>ю</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/scss/base/mixins";

.hamburger {
  @media (width >= 992px) {
    .hamburger-label {
      --c-hamburger-label: var(--c-text-primary);

      color: var(--c-hamburger-label);

      body.is-page-home & {
        --c-hamburger-label: var(--c-text-secondary);
      }

      body.is-drawer & {
        --c-hamburger-label: transparent;
      }
    }

    .ham-close {
      --c-hamburger-close: var(--c-text-secondary);

      stroke: var(--c-hamburger-close);
    }

    .ham-d-one,
    .ham-d-two {
      --c-hamburger-close: var(--c-text-primary);

      stroke: var(--c-hamburger-close);

      body.is-page-home & {
        --c-hamburger-close: var(--c-text-secondary);
      }

      body.is-drawer & {
        --c-hamburger-close: transparent;
      }
    }
  }

  @media (width <= 991.98px) {
    .ham-m-one,
    .ham-m-two {
      stroke: var(--c-text-secondary);
    }
  }

  position: relative;
  height: 100%;
  display: inline-flex;
  align-items: center;
  z-index: var(--z-index-hamburger);
  cursor: pointer;

  @media (width <= 991.98px) {
    position: fixed;
    top: 0; right: 0;
    height: 80px;
    mix-blend-mode: difference;
  }

  body.is-hamburger & {
    z-index: var(--z-index-priority);
  }

  &:hover {
    .hamburger-svg {
      .ham-d-one {
        stroke-dashoffset: -103;
      }

      .ham-d-two {
        stroke-dashoffset: -103;
      }
    }
  }

  .hamburger-svg {
    width: 80px;
    height: 80px;

    @media (width >= 992px) {
      .ham-close {
        stroke-dasharray: 31.11 149.9066;
        stroke-dashoffset: -74;
        transition:
          stroke 0.4s 0.3s cubic-bezier(0.6, 0, 0.04, 1),
          stroke-dashoffset 0.4s 0.3s cubic-bezier(0.6, 0, 0.04, 1);
      }

      .ham-d-one,
      .ham-d-two {
        stroke-width: 1;
        stroke-dasharray: 40 42;
        stroke-dashoffset: -21;
      }

      .ham-d-one {
        transition:
          stroke-dashoffset 0.4s cubic-bezier(0.6, 0, 0.04, 1),
          stroke 0.4s 0.4s ease;
      }

      .ham-d-two {
        transition:
          stroke-dashoffset 0.4s 0.075s cubic-bezier(0.6, 0, 0.04, 1),
          stroke 0.4s 0.4s ease;
      }

      body.is-drawer & {
        .ham-close {
          stroke-dashoffset: 0;
        }

        .ham-d-one,
        .ham-d-two {
          transition: stroke 0.2s ease;
        }
      }
    }

    @media (width <= 991.98px) {
      .ham-m-one,
      .ham-m-two {
        stroke-width: 2;
        stroke-dasharray: 32 105.2781;
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 0.7s cubic-bezier(0.5, 0, 0, 1);
      }

      body.is-drawer & {
        .ham-m-one,
        .ham-m-two {
          stroke-dashoffset: -73.2781;
        }
      }
    }
  }

  @media (width >= 992px) {
    .hamburger-label {
      overflow: hidden;
      font-family: var(--font-family-extra);
      text-transform: uppercase;
      font-size: 18px;
      letter-spacing: 1px;
      line-height: 1;
      transition: color 0.4s 0.6s ease;

      body.is-hamburger & {
        transition: color 0.1s ease;
      }

      span {
        display: inline-block;
        font-weight: 600;
      }
    }

    .hamburger-label {
      span {
        animation: paired-label-out 480ms cubic-bezier(0.5, 0, 0, 1);

        @for $i from 1 through 4 {
          &:nth-child(#{$i}) {
            animation-delay: #{$i * 15}ms;
          }
        }

        @keyframes paired-label-out {
          0% {transform: translate3d(0, 0, 0);}
          49.9% {transform: translate3d(0, -75%, 0);}
          50% {transform: translate3d(0, 75%, 0);}
          100% {transform: translate3d(0, 0, 0);}
        }
      }
    }

    &:hover {
      .hamburger-label {
        span {
          animation: paired-label-in 480ms cubic-bezier(0.5, 0, 0, 1);

          @for $i from 1 through 4 {
            &:nth-child(#{$i}) {
              animation-delay: #{$i * 15}ms;
            }
          }

          @keyframes paired-label-in {
            0% {transform: translate3d(0, 0, 0);}
            49.9% {transform: translate3d(0, 75%, 0);}
            50% {transform: translate3d(0, -75%, 0);}
            100% {transform: translate3d(0, 0, 0);}
          }
        }
      }
    }
  }
}
</style>
