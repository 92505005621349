<script>
import {
  ref, onMounted, onBeforeUnmount,
} from 'vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'AppParallax',
  setup() {
    const parallaxWrapRef = ref();
    const parallaxItemRef = ref();
    const tlParallax = gsap.timeline({ paused: true });

    const animation = () => {
      if (parallaxWrapRef.value || parallaxItemRef.value) {
        const diff = parallaxItemRef.value.offsetHeight - parallaxWrapRef.value.offsetHeight;

        tlParallax.fromTo(parallaxItemRef.value, {
          y: () => -diff,
        }, {
          y: 0,
          ease: 'none',
          force3D: true,
          scrollTrigger: {
            trigger: parallaxWrapRef.value,
            scrub: true,
          },
        });
      }
    };

    onMounted(animation);

    onBeforeUnmount(() => {
      tlParallax.kill();
    });

    return {
      parallaxWrapRef,
      parallaxItemRef,
    };
  },
};
</script>

<template lang="html">
  <div
    ref="parallaxWrapRef"
    class="parallax"
  >
    <div
      ref="parallaxItemRef"
      class="parallax__item"
    >
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.parallax {
  position: relative;
  overflow: hidden;
  z-index: 1;
  height: 100%;

  &__item {
    position: absolute;
    inset: 0;
    height: 146%;
    will-change: transform;
  }
}
</style>
