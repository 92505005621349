<script>
import { ref, watchEffect } from 'vue';
import useDrawer from '../store/drawer';
import useMedia from '../store/media';
import AppDrawerNav from './AppDrawerNav.vue';
import AppDrawerImage from './AppDrawerImage.vue';

export default {
  name: 'AppDrawer',
  components: {
    AppDrawerNav,
    AppDrawerImage,
  },
  setup() {
    const drawer = useDrawer();
    const media = useMedia();
    const body = ref(document.body);

    watchEffect(() => {
      if (drawer.visible) {
        body.value.classList.add('is-drawer', 'is-hamburger');
      } else {
        body.value.classList.remove('is-hamburger');
        setTimeout(() => body.value.classList.remove('is-drawer'), 300);
      }
    });

    return {
      media,
    };
  },
};
</script>

<template lang="html">
  <div class="drawer">
    <AppDrawerImage v-if="media.tablet" />
    <AppDrawerNav />
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/scss/base/mixins";

.drawer {
  pointer-events: none;
  position: fixed;
  inset: 0;
  z-index: var(--z-index-drawer);
  width: 100vw;
  height: 100%; /* Именно %, от vh units iOS Safari херовеет (ломается анимация связанная с высотой) */
  display: grid;
  grid-template-columns: 1fr;
  transform: translateY(-101%);

  @include min-width(tablet-landscape) {
    grid-template-columns: calc((100vw / 12) * 6.65) calc((100vw / 12) * 5.35);
  }
}
</style>
