<script>
import LayoutHeader from './LayoutHeader.vue';
import LayoutFooter from './LayoutFooter.vue';
import AppDrawer from '../components/AppDrawer.vue';
import AppTransition from '../components/AppTransition.vue';
import useLoading from '../store/loading';

export default {
  name: 'LayoutMain',
  components: {
    LayoutHeader,
    LayoutFooter,
    AppDrawer,
    AppTransition,
  },
  setup() {
    const loading = useLoading();

    return {
      loading,
    };
  },
};
</script>

<template>
  <LayoutHeader />
  <main class="main">
    <slot />
  </main>
  <LayoutFooter v-if="loading.complete" />
  <AppDrawer />
  <AppTransition />
</template>
