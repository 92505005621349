<script>
import {
  ref, onMounted, watchEffect,
} from 'vue';
import { gsap } from 'gsap';
import useDrawer from '../store/drawer';

export default {
  name: 'AppDrawerNavMenu',
  setup() {
    const drawer = useDrawer();
    const drawerNavMenuRef = ref();
    const drawerNavMenuItemsRef = ref([
      { text: 'Компания', to: '/' },
      { text: 'История', to: '/history' },
      { text: 'Проекты', to: '/decision' },
      { text: 'Контакты', to: '/contact' },
    ]);

    const tlDrawerNavMenuOpen = gsap.timeline({ paused: true });
    const tlDrawerNavMenuClose = gsap.timeline({ paused: true });

    const animation = () => {
      const targets = gsap.utils.toArray(drawerNavMenuRef.value.children);

      if (drawerNavMenuRef.value) {
        tlDrawerNavMenuOpen
          .from(targets, {
            duration: 0.5,
            yPercent: -90,
            autoAlpha: 0,
            ease: 'sine.out',
            stagger: {
              amount: 0.3,
            },
          }, '<+=0.5');

        tlDrawerNavMenuClose
          .from(targets, {
            duration: 0.7,
            autoAlpha: 1,
            ease: 'sine.in',
          });
      }
    };

    onMounted(animation);

    watchEffect(() => {
      if (drawer.visible) {
        tlDrawerNavMenuOpen.restart();
      } else {
        tlDrawerNavMenuClose.restart();
      }
    });

    return {
      drawerNavMenuRef,
      drawerNavMenuItemsRef,
    };
  },
};
</script>

<template lang="html">
  <nav class="drawer-nav-menu" ref="drawerNavMenuRef">
    <template
      v-for="item in drawerNavMenuItemsRef"
      :key="item"
    >
      <div  class="drawer-nav-menu__item">
        <AppLink
          class="link--drawer"
          :throttle="1000"
          :changed="1000"
          :to="item.to"
        >
          {{ item.text }}
        </AppLink>
      </div>
    </template>
  </nav>
</template>

<style lang="scss" scoped>
@import "../assets/scss/base/mixins";

.drawer-nav-menu {
  padding: calc(var(--header-height) + 6vh) 4vw 0 12vw;
  display: grid;
  grid-template-rows: repeat(auto-fill, minmax(70px, auto));
  align-self: center;

  @include min-width(tablet-landscape) {
    padding: calc(var(--header-height) + 6vh) 6vw 0 6vw;
  }
}
</style>
