import { createApp } from 'vue';
import { createPinia } from 'pinia';

import AppIcon from 'vue-sprite';
import AppButton from 'btn-kit';
import AppLink from 'vue-app-link';
import AppImage from './components/AppImage.vue';
import AppParallax from './components/AppParallax.vue';
import App from './App.vue';
import router from './router';

const app = createApp(App);

app.use(createPinia());
app.use(router());
app.component('AppIcon', AppIcon);
app.component('AppButton', AppButton);
app.component('AppLink', AppLink);
app.component('AppImage', AppImage);
app.component('AppParallax', AppParallax);
app.mount('#app');
