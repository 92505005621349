import { defineStore } from 'pinia';

const useTransition = defineStore({
  id: 'useTransition',
  state: () => ({
    expand: null,
    collapse: null,
  }),
  actions: {
    setExpand(value) {
      this.expand = value;
    },
    setCollapse(value) {
      this.collapse = value;
    },
    playExpand() {
      if (this.expand) {
        this.expand.restart();
      }
    },
    playCollapse() {
      if (this.collapse) {
        this.collapse.restart();
      }
    },
  },
});

export default useTransition;
