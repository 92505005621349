<script>
import { ref, onMounted } from 'vue';
import { gsap } from 'gsap';
import useTransition from '../store/transition';

export default {
  name: 'AppTransition',
  setup() {
    const transition = useTransition();
    const transitionRef = ref();

    const expand = gsap.timeline({ paused: true });
    const collapse = gsap.timeline({ paused: true });

    const animation = () => {
      transition.setExpand(expand);
      transition.setCollapse(collapse);

      expand
        .set(transitionRef.value, {
          top: 'auto',
          bottom: '0',
          height: '0',
          borderRadius: '100% 100% 0 0',
        })
        .to(transitionRef.value, {
          duration: 1.2,
          height: '100%',
          borderRadius: '0 0 0 0',
          ease: 'power2.inOut',
          force3D: true,
        });

      collapse
        .set(transitionRef.value, {
          top: '0',
          bottom: 'auto',
          height: '100%',
          borderRadius: '0 0 0 0',
        })
        .to(transitionRef.value, {
          duration: 1.2,
          height: '0',
          borderRadius: '0 0 100% 100%',
          ease: 'power2.inOut',
          force3D: true,
        });
    };

    onMounted(animation);

    return {
      transitionRef,
    };
  },
};
</script>

<template lang="html">
  <Teleport to="#app">
    <div class="app-transition" ref="transitionRef"></div>
  </Teleport>
</template>

<style lang="scss" scoped>
.app-transition {
  position: fixed;
  top: 0;
  bottom: auto;
  z-index: var(--z-index-app-transition);
  margin: auto;
  width: 100vw;
  height: 100vh;
  will-change: border-radius, height;
  background-color: var(--c-background-primary);
}
</style>
